import {green} from "@material-ui/core/colors";


export const useStyles = theme => ({
  root: {
    // width: "100%",
    // position: 'absolute',
    // "max-width": 800,
    paddingTop: 20,
    marginBottom: theme.spacing(6),
    // minHeight: 500,
    // height: "50%",

    // display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: "center",
    alignItems: 'center',
  },
  mainContainer: {
    flexDirection: 'column',
    "max-width": 800,
    flex: 1,
    margin: 'auto',
    // 'text-align': 'left',
    justifyContent: "center",
    alignItems: 'center',
  },
  videoPlayer: {
    "max-width": 800,
    // minWidth: "500",
    width: "100%",
    height: "60%",
    margin: "auto",
    // position: 'relative',
    // justifyContent: "center",
    // alignItems: 'center',
    flex: 1,
    // minHeight: 20,
  },
  recordingButton: {
    flex: 1,
    margin: "auto",
    "max-width": 800,
    width: '100%',
    paddingTop: 20,
    // backgroundColor: "blue",
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  textForm: {
    // margin: theme.spacing(1),
    // margin: 'auto',
    // justify: "center",
    minWidth: 300,
    maxWidth: '80%',
    flex: 1,
  },
  buttonForm: {
    // margin: theme.spacing(1),
    // justify: "center",
    // margin: 'auto',
    marginTop: 20,
    flex: 1,
    minWidth: 300,
    maxWidth: '80%',
    // 'font-weight': 'bold',
    color: 'white',
    backgroundColor: '#ef6c24'
  },
  emailForm: {
    flex: 1,
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    'flex-direction': 'column',
    padding: 10,
    marginTop: 10,
    // margin: 'auto',
  },
  formControl: {
    // margin: theme.spacing(4),
    minWidth: 300,
    maxWidth: 500,
    justifyContent: "center"
  },
  slider: {
    marginWidth: theme.spacing(4),
    minWidth: 200,
    maxWidth: 300,
    justifyContent: "center"
  },
  list: {
    margin: theme.spacing(4),
    minWidth: 200,
    justifyContent: "center"
  },
  container: {
    marginWidth: theme.spacing(10),
    justify: "center",
    alignItems: "center"
  },
  button: {
    width: '50%',
    backgroundColor: 'light-blue',
  },
  subTitleText: {
    margin: theme.spacing(3),
  },
  title2: {
    marginTop: theme.spacing(6),
  },
  mainText: {
    margin: theme.spacing(3),
    'text-align': 'left',
    // justify: "flex-start",
    // alignItems: "flex-start"
  },
  boldText: {
    'font-weight': 'bold',
  }
});
