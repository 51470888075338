import React from 'react';
import './App.css';
import {Button, TextField} from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';
import {useStyles} from "./MaterialUIStyles"
import {withStyles} from '@material-ui/core/styles';
import {
  Player,
  ControlBar,
  Shortcut,
  BigPlayButton,
  CurrentTimeDisplay,
  TimeDivider,
  DurationDisplay
} from 'video-react';
import logo from './assets/juice_logo_transparent_text_only.png'
import MicRecorder from 'mic-recorder-to-mp3';
import {UAParser} from 'ua-parser-js';
import config from './config.json';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userApproval: false,
      mikePermission: false,
      isPlaying: false,
      isRecording: false,
      finishedRecording: false,
      firstName: '',
      email: '',
      emailSubmitted: false,
      playerSubscribed: false,
      isLoading: false,
      isUploaded: false,
      failedUpload: false,
    };
    this.player = null;
    this.Mp3Recorder = new MicRecorder({bitRate: 128});
  }

  componentDidMount() {
  }

  handleEmailSubmit = () => {
    if (this.state.firstName && this.state.email) {
      this.setState({emailSubmitted: true});
    }
  };

  onEmailChange = (event) => {
    this.setState({email: event.target.value});
  }

  onFirstNameChange = (event) => {
    this.setState({firstName: event.target.value});
  }

  askMikePermission = () => {
    navigator.mediaDevices && navigator.mediaDevices.getUserMedia({audio: true})
        .then(() => {
          this.setState({mikePermission: true})
          console.log('Permission Granted');
        }).catch(() => {
          console.log('Permission Denied');
        });
  }

  startPlayerSubscriber = () => {
    console.log('Subscribing: ');
    this.player.subscribeToStateChange((state) => {
      if (this.state.finishedRecording) {
        return;
      }
      if (this.state.isPlaying && state.currentTime < 5 && state.isFullscreen) {
        this.player.toggleFullscreen();
        console.log('Toogling full screen');
      }
      // Start recording after x seconds
      if (!this.state.isRecording && state.currentTime > 110) {
        this.setState({isRecording: true});
        this.Mp3Recorder
            .start()
            .then(() => {
              console.log('Recording started');
            }).catch((e) => console.error(e));
      }
      // Stop the recording if we hit the end of the video
      if (this.state.isRecording && state.currentTime > state.duration - 25) {
        this.Mp3Recorder.stop();
        this.setState({
          isRecording: false,
          finishedRecording: true
        })
      }
    });
  }

  startRecording = () => {
    this.player && this.player.play();
    this.setState({isPlaying: true});
  }

  restartRecording = () => {
    this.Mp3Recorder.stop();
    this.setState({
      isPlaying: false,
      finishedRecording: false
    });
    this.player.seek(0);
    this.player.pause();
  }

  uploadAudio = (data) => {
    return fetch(config.uploadUrl, {
      headers: {
        'Access-Control-Allow-Origin': "*",
        // 'Content-Type': 'multipart/form-data',
        filename: this.state.firstName + '_' + this.state.email + '.mp3'
      },
      method: 'POST',
      body: data
    }).then((res) => {
      //console.log(res);
      if (res.status === 200) {
        this.setState({isUploaded: true});
      }
    }).catch((e) => {
      console.log(e);
      this.setState({failedUpload: true});
      setTimeout(() => {
        this.setState({
          failedUpload: false,
        });
        setTimeout(() =>
            this.uploadAudio(data), 2000);
      }, 3000);
    });
  };

  stopRecording = () => {
    this.Mp3Recorder
        // .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          this.player.seek(0);
          this.player.pause();
          this.setState({
            isRecording: false,
            isPlaying: false,
            finishedRecording: false,
          })
          const data = new FormData();
          data.append('data', blob);

          //Upload isLoading
          this.setState({isLoading: true});
          return this.uploadAudio(data);

        }).catch((e) => console.log(e));
  };

  render() {
    const {classes} = this.props;
    const parser = new UAParser();
    let mobileBrowserIsOk = true;
    if (parser.getDevice().type === 'mobile') {
      if (parser.getDevice().vendor === 'Apple') {
        //  IOS, we want safari
        mobileBrowserIsOk = parser.getBrowser().name.includes('Safari');
      } else {
        //  Android, we want chrome.
        mobileBrowserIsOk = parser.getBrowser().name.includes('Chrome');
      }
    }
    return (
        <div className="App">
          {/* --- Header ---*/}
          <header className="App-header">
            <div className="Header-box">
              <div className="Juice-logo">
                <img className="Juice-image" src={logo} alt="Logo"/>
              </div>
              <div className="App-title">
                {!this.state.userApproval
                    ? <p>Bienvenue sur notre site de cueillette de voix</p>
                    : this.state.firstName && this.state.email && this.state.emailSubmitted
                        ? <p>Cueillette</p>
                        : <p>Inscription</p>
                }
              </div>
              <div className="Juice-logo"/>
            </div>
          </header>
          {!mobileBrowserIsOk ?
              <div className={classes.root}>
                <div className={classes.mainText}>
                  <p className={classes.boldText}>Ce navigateur nous semble incompatible.</p>
                  Si vous êtes sur mobile (ce que nous suspectons fortement), assurez-vous s'il vous plaît
                  d'utiliser <br/>
                  <p className={classes.boldText}>Safari sur iPhone</p> et
                  <p className={classes.boldText}>Chrome sur Android</p>
                  Ceci pour des raisons de compatibilité de microphone.
                  <br/><br/>Merci beaucoup!
                </div>
              </div>
              :
              <>
                {!this.state.userApproval ?
                    <div className={classes.root}>
                      {/* --- Ask for the user approval --- */}
                      <div className={classes.mainContainer}>
                        <p className={classes.mainText}>
                          Bonjour et mille mercis de contribuer au projet de Juice en donnant quelques
                          petits échantillons de votre voix.
                        </p>
                        <Button
                            variant='contained'
                            onClick={() => {
                              this.setState({userApproval: true});
                            }}
                            className={classes.buttonForm}>Commencer l’enregistrement</Button>
                        <h2 className={classes.title2}>En savoir plus</h2>
                        <div className={classes.mainText}>Juice est une app audio qui vous glissera à
                          l’oreille les choses utiles, intéressantes et agréables que vous avez envie de
                          savoir. C’est une sorte de compagnon, auquel on pourra, bien sûr, parler en lui
                          donnant des instructions vocales.

                          Pour que Juice apprenne à bien comprendre les ordres qu’on lui donne, nous
                          devons l'entraîner. Nous devons lui faire écouter ces ordres des milliers de
                          fois, prononcés par la plus grande variété possible de voix.

                          <p className={classes.boldText}>
                            C’est pour cela que nous avons besoin de vous: nous vous demandons
                            d’enregistrer une douzaine de mots, répétés chacun 5 fois. C’est facile,
                            notre vidéo vous guidera tout au long, comme dans un karaoke. Cela vous
                            prendra moins de 10 minutes.
                          </p>

                          Il est important de préciser que votre voix ne sera jamais diffusée. Elle sera
                          uniquement utilisée avec celle de centaines d’autres gens pour entraîner Juice à
                          la reconnaissance de ces mots et uniquement de ces mots. Par souci de respect de
                          la vie privée, Juice ne comprendra que les quelques mots de vocabulaire dont il
                          a besoin pour servir ses utilisateurs et ignorera tout le reste.
                          <br/><br/>
                          Si vous acceptez de participer à cette aventure en enregistrant votre voix, vous
                          aurez bien entendu accès, en avant première, à l’App de Juice. Ce sera notre
                          manière de vous remercier pour votre précieuse contribution à ce projet.

                          Encore merci pour votre aide.
                        </div>
                        <Button
                            variant='contained'
                            onClick={() => {
                              this.setState({userApproval: true})
                            }}
                            className={classes.buttonForm}>
                          Je veux enregistrer ma voix
                        </Button>
                      </div>
                    </div>
                    :
                    <>
                      {/* --- User approved! let's ask for an email and then shoot the video! --- */}
                      {this.state.firstName && this.state.email && this.state.emailSubmitted ?
                          <LoadingOverlay
                              active={this.state.isLoading}
                              spinner={!this.state.failedUpload && !this.state.isUploaded}
                              text={this.state.failedUpload ?
                                  "😭 Echec de l'upload. Nouvel essai dans 5 secondes..." : this.state.isUploaded ?
                                      "Merci, nous avons bien reçu votre échantillon ! 😄" :
                                      "Upload de l'échantillon en cours... "}
                          >
                            <div className={classes.root}>
                              {/* --- User approved AND we have her/his email! Let's shoot the video and recording now --- */}
                              {/* --- Video --- */}
                              <div className={classes.videoPlayer}>
                                <Player
                                    playsInline={true}
                                    src={config.videoUrl}
                                    ref={player => {
                                      this.player = player;
                                      if (!this.state.playerSubscribed) {
                                        this.setState({playerSubscribed: true});
                                        this.startPlayerSubscriber();
                                        this.askMikePermission();
                                      }
                                    }}
                                >
                                  <ControlBar autoHide={false} disableDefaultControls={true}>
                                    <CurrentTimeDisplay/>
                                    <TimeDivider/>
                                    <DurationDisplay/>
                                  </ControlBar>
                                  <Shortcut clickable={false}/>
                                  <BigPlayButton disabled/>
                                </Player>
                              </div>
                              {/* --- Recording --- */}
                              <div className={classes.recordingButton}>
                                <Button className={classes.button} onClick={this.startRecording}
                                        disabled={this.state.isPlaying || !this.state.mikePermission}>Commencer</Button>
                                <Button className={classes.button} onClick={this.restartRecording}
                                        disabled={!this.state.isPlaying}>Recommencer</Button>
                                <Button className={classes.button} onClick={this.stopRecording}
                                        disabled={!this.state.finishedRecording}>Terminer -
                                  Envoyer</Button>
                              </div>
                            </div>
                          </LoadingOverlay>
                          :
                          <div className={classes.root}>
                            {/* --- User approved, ask for her/his email now --- */}
                            <div className={classes.mainContainer}>
                              <div>
                                <form className={classes.emailForm} onSubmit={this.handleEmailSubmit}>
                                  <TextField id="standard-basic" label="Votre prénom*"
                                             className={classes.textForm}
                                             onChange={this.onFirstNameChange}/>
                                  <TextField id="standard-basic" label="Votre email*"
                                             className={classes.textForm}
                                             onChange={this.onEmailChange}/>
                                  <Button onClick={this.handleEmailSubmit}
                                          className={classes.buttonForm}>OK</Button>
                                </form>
                              </div>
                              <div className={classes.mainText}>
                                *Ces informations nous permettent de distinguer les enregistrements les
                                uns des autres ainsi qu’à vous tenir au courant des progrès du projet
                                Juice et de la disponibilité de son App.
                                <br/><br/>
                                Il est également nécessaire d’autoriser l’utilisation de votre micro,
                                pour des raisons évidentes.
                              </div>
                            </div>
                          </div>
                      }
                    </>
                }
              </>
          }
        </div>
    )
  }
}

export default withStyles(useStyles)(App)
